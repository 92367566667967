import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ucFirst } from '../../utils/caseconverters.js';
import styles from './Icon.module.scss';

import dynamic from 'next/dynamic';
import i18n from '../../i18n';

const Bookmark = dynamic(() =>
    import('./icons/bookmark.svg').then((mod) => mod.ReactComponent)
);
const Arrow = dynamic(() =>
    import('./icons/arrow.svg').then((mod) => mod.ReactComponent)
);
const ArrowLeft = dynamic(() =>
    import('./icons/arrowLeft.svg').then((mod) => mod.ReactComponent)
);
const BookmarkFilled = dynamic(() =>
    import('./icons/bookmarkFilled.svg').then((mod) => mod.ReactComponent)
);
const BookmarkFilledSmaller = dynamic(() =>
    import('./icons/bookmarkFilledSmaller.svg').then((mod) => mod.ReactComponent)
);
const Calendar = dynamic(() =>
    import('./icons/calendar.svg').then((mod) => mod.ReactComponent)
);
const Check = dynamic(() =>
    import('./icons/check.svg').then((mod) => mod.ReactComponent)
);
const Chevron = dynamic(() =>
    import('./icons/chevron.svg').then((mod) => mod.ReactComponent)
);
const CheckRound = dynamic(() =>
    import('./icons/checkRound.svg').then((mod) => mod.ReactComponent)
);
const Close = dynamic(() =>
    import('./icons/close.svg').then((mod) => mod.ReactComponent)
);
const Error = dynamic(() =>
    import('./icons/error.svg').then((mod) => mod.ReactComponent)
);
const External = dynamic(() =>
    import('./icons/external.svg').then((mod) => mod.ReactComponent)
);
const Facebook = dynamic(() =>
    import('./icons/facebook.svg').then((mod) => mod.ReactComponent)
);
const Filter = dynamic(() =>
    import('./icons/filter.svg').then((mod) => mod.ReactComponent)
);
const Feeling1 = dynamic(() =>
    import('./icons/feeling1.svg').then((mod) => mod.ReactComponent)
);
const Feeling2 = dynamic(() =>
    import('./icons/feeling2.svg').then((mod) => mod.ReactComponent)
);
const Feeling3 = dynamic(() =>
    import('./icons/feeling3.svg').then((mod) => mod.ReactComponent)
);
const Feeling4 = dynamic(() =>
    import('./icons/feeling4.svg').then((mod) => mod.ReactComponent)
);
const Feeling5 = dynamic(() =>
    import('./icons/feeling5.svg').then((mod) => mod.ReactComponent)
);
const Information = dynamic(() =>
    import('./icons/information.svg').then((mod) => mod.ReactComponent)
);
const Instagram = dynamic(() =>
    import('./icons/instagram.svg').then((mod) => mod.ReactComponent)
);
const LinkedIn = dynamic(() =>
    import('./icons/linkedIn.svg').then((mod) => mod.ReactComponent)
);
const List = dynamic(() =>
    import('./icons/list.svg').then((mod) => mod.ReactComponent)
);
const Mail = dynamic(() =>
    import('./icons/mail.svg').then((mod) => mod.ReactComponent)
);
const Map = dynamic(() =>
    import('./icons/map.svg').then((mod) => mod.ReactComponent)
);
const Message = dynamic(() =>
    import('./icons/message.svg').then((mod) => mod.ReactComponent)
);
const Messenger = dynamic(() =>
    import('./icons/messenger.svg').then((mod) => mod.ReactComponent)
);
const Minus = dynamic(() =>
    import('./icons/minus.svg').then((mod) => mod.ReactComponent)
);
const Mute = dynamic(() =>
    import('./icons/mute.svg').then((mod) => mod.ReactComponent)
);
const Sound = dynamic(() =>
    import('./icons/sound.svg').then((mod) => mod.ReactComponent)
);
const Pause = dynamic(() =>
    import('./icons/pause.svg').then((mod) => mod.ReactComponent)
);
const Phone = dynamic(() =>
    import('./icons/phone.svg').then((mod) => mod.ReactComponent)
);
const Place = dynamic(() =>
    import('./icons/place.svg').then((mod) => mod.ReactComponent)
);
const Play = dynamic(() =>
    import('./icons/play.svg').then((mod) => mod.ReactComponent)
);
const Plus = dynamic(() =>
    import('./icons/plus.svg').then((mod) => mod.ReactComponent)
);
const Search = dynamic(() =>
    import('./icons/search.svg').then((mod) => mod.ReactComponent)
);
const Share = dynamic(() =>
    import('./icons/share.svg').then((mod) => mod.ReactComponent)
);
const Thumbsdown = dynamic(() =>
    import('./icons/thumbsdown.svg').then((mod) => mod.ReactComponent)
);
const Thumbsup = dynamic(() =>
    import('./icons/thumbsup.svg').then((mod) => mod.ReactComponent)
);
const Trash = dynamic(() =>
    import('./icons/trash.svg').then((mod) => mod.ReactComponent)
);
const Twitter = dynamic(() =>
    import('./icons/twitter.svg').then((mod) => mod.ReactComponent)
);
const Web = dynamic(() =>
    import('./icons/web.svg').then((mod) => mod.ReactComponent)
);
const Bus = dynamic(() =>
    import('./icons/bus.svg').then((mod) => mod.ReactComponent)
);
const Subway = dynamic(() =>
    import('./icons/subway.svg').then((mod) => mod.ReactComponent)
);
const Bike = dynamic(() =>
    import('./icons/bike.svg').then((mod) => mod.ReactComponent)
);
const Walk = dynamic(() =>
    import('./icons/walk.svg').then((mod) => mod.ReactComponent)
);

const CloseBold = dynamic(() =>
    import('./icons/closebold.svg').then((mod) => mod.ReactComponent)
);

export const allIcons = {
    arrow: Arrow,
    arrowLeft: ArrowLeft,
    bookmark: Bookmark,
    bookmarkFilled: BookmarkFilled,
    bookmarkFilledSmaller: BookmarkFilledSmaller,
    calender: Calendar,
    check: Check,
    checkRound: CheckRound,
    chevron: Chevron,
    close: Close,
    closebold: CloseBold,
    error: Error,
    external: External,
    facebook: Facebook,
    feeling1: Feeling1,
    feeling2: Feeling2,
    feeling3: Feeling3,
    feeling4: Feeling4,
    feeling5: Feeling5,
    filter: Filter,
    information: Information,
    instagram: Instagram,
    linkedIn: LinkedIn,
    list: List,
    mail: Mail,
    map: Map,
    message: Message,
    messenger: Messenger,
    minus: Minus,
    mute: Mute,
    sound: Sound,
    pause: Pause,
    phone: Phone,
    place: Place,
    play: Play,
    plus: Plus,
    search: Search,
    share: Share,
    twitter: Twitter,
    thumbsdown: Thumbsdown,
    thumbsup: Thumbsup,
    trash: Trash,
    web: Web,
    bus: Bus,
    subway: Subway,
    bike: Bike,
    walk: Walk,
};

const Icon = ({
    type,
    color,
    size,
    hoverColor,
    round,
    modalSize,
    modifiers,
    sizeXLMobile,
    iconModifiers = [],
}) => {
    if (!type) {
        return;
    }

    const Svg = allIcons[type];
    const hover = hoverColor && ucFirst(hoverColor);

    return (
        <span
            className={classNames(
                modifiers.map((x) => ' ' + x),
                iconModifiers.map((x) => styles[`Icon--${x}`]),
                styles['Icon'],
                {
                    [styles['Icon--Round']]: round,
                    [styles['Icon--ModalSize']]: modalSize,
                }
            )}>
            <span className="sr-only">{i18n.t(`Icon.${type}AltText`)}</span>
            <span
                aria-hidden
                className={classNames(
                    styles['Icon'],
                    styles['Icon--' + ucFirst(type)],
                    styles['Icon--' + ucFirst(size)],
                    {
                        [styles['Icon--Hover' + hover]]: hover,
                        [styles['Icon--' + ucFirst(color)]]: color,
                        [styles['Icon--SizeXLMobile']]: sizeXLMobile,
                    }
                )}>
                {!!Svg && <Svg />}
            </span>
        </span>
    );
};

Icon.propTypes = {
    type: PropTypes.oneOf(Object.keys(allIcons)),
    color: PropTypes.oneOf([
        'white',
        'black',
        'black750',
        'black800',
        'pink',
        'error',
        'gray10',
        'gray100',
        'gray500',
        'gray700',
        'pink700',
        'colorGray40',
    ]),
    hoverColor: PropTypes.oneOf(['white', 'black800', 'pink']),
    size: PropTypes.oneOf([
        'xs',
        'small',
        'sm',
        'medium',
        'large',
        'xl',
        'fill',
    ]),
    modalSize: PropTypes.bool,
    modifiers: PropTypes.array,
    sizeXLMobile: PropTypes.bool,
};

Icon.defaultProps = {
    color: 'white',
    size: 'medium',
    hoverColor: null,
    round: false,
    type: 'arrow',
    modalSize: false,
    modifiers: [],
    sizeXLMobile: null,
};

export default Icon;
